import React from 'react'
import axios from 'axios'
import { MDBIcon, MDBBtn, MDBInput } from 'mdbreact';
import SideNav from './side-nav'
import { Redirect } from 'react-router-dom';
import './../App.css'
import ENV from './../env.json'

export default class ChangePassword extends React.Component {
    constructor(props) {
        super()
        this.state = {
            modalIsOpen: false,
            oldPass: '',
            newPass: '',
            confirmPass: '',
            isChanged:false
        }

    }
    //modal methhod
    onMoedalInputChang = e => {
        this.setState({ [e.target.name]: e.target.value })

    }
    onModelClick = (e) => {
        e.preventDefault();
        var that = this
        if (this.state.newPass !== this.state.confirmPass) {
            alert("MisMatch new and confirm Password")

        } else {
           
            let user = sessionStorage.getItem('user')
            let pass = this.state.oldPass;
            let newpass = this.state.newPass;
            let rowBody = "ParamsStart#@!unm:" + user + "#@!pwd:" + pass + "#@!newpwd:" + newpass + "#@!Paramsend"
            console.log("change password row body : ", rowBody)
            let url = ENV.CHANGE_PASSWORD_API
            axios.post(
                url,
                rowBody,
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'text/plain'
                    }
                }

            ).then(res => {
                console.log("res=>", res)
                if (res.data === true) {
                    console.log("iiii")
                    alert("Password has been changed successfully!!!")
                    that.setState({isChanged:true})
                   
                } else if (res.data === false) {

                    alert("MisMatch Password!!!")
                }
            })
        }
    }
    onInputChang = (e) => {
        this.setState({ [e.target.name]: e.target.value });

    }

    render() {
        if(this.state.isChanged === true){
            return <Redirect to='/list'></Redirect>
       
        } 
        return (
            <div  >
                <div style={{float:'left'}}><SideNav /></div>
                <div className='row' >
                    <div style={{ margin: '10% 20%', border: '1px solid rgba(0,0,0,0.2)', borderRadius: '5px', boxShadow: ' 0 8px 16px 0 rgba(0,0,0,0.2)', width: '600px' }}>
                        <div className="card-body card-body-cascade text-center">
                            <form >
                            <div style={{ fontSize: '22px', fontWeight: 'bold', padding: '1rem', backgroundColor: '#e2492f', color: 'white' }}>Change Password</div>

                                <MDBInput label="Old Password" type="password" name="oldPass" value={this.state.oldPass} onChange={this.onInputChang} required />
                                <MDBInput label='New Password' type="password" name="newPass" value={this.state.newPass} onChange={this.onInputChang} required />
                                <MDBInput label='Confirm Password' type="password" name="confirmPass" value={this.state.confirmPass} onChange={this.onInputChang} required />
                                <button style={{ backgroundColor: 'rgb(255, 209, 67)', borderRadius: '5px', border: '0', padding: '0.5rem 1rem', fontSize: '0.8rem', lineHeight: '1.5' }}
                                    onClick={this.onModelClick}>Submit</button>

                            </form>
                        </div>
                    </div>
                </div>
            </div>

        )

    }
}

