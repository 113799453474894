import React from 'react';
import './App.css';
import List from './component/list'
import HeaderLayout from './component/HeaderLayout'
import DefaultFooter from './component/footer'
import { BrowserRouter as Router, Link, Route, HashRouter,Switch } from 'react-router-dom'
import HomePage from './component/homepage'
import ChangePassword from './component/changePassword'
import S3 from './component/s3Dashboard'
import CloudFrontList from './component/cloudFrontList';
import Footer from './component/footer';
// import Amplify, { Auth } from 'aws-amplify';
// import awsconfig from './aws-exports';

// Amplify.configure(awsconfig);



var loader = ""
class App extends React.Component {
   constructor(props){
     super(props)
       this.state={
         ishidden:true,
         passwordComponentEnable:false
       }
     }
   
     
     
  render() { 
    return (
      <div>
        {/* <customInputList/> */}
        <HeaderLayout />
        <div >
          <HashRouter>
            <div>
              <Switch>
                <Route exact path="/" component={List} />
                <Route exact path="/login" component={HomePage}/>

                <Route exact path="/chanpepass" component={ChangePassword}/>
                <Route exact path="/list" component={List }/>
                <Route exact path="/s3" component={S3 }/>
                <Route exact path="/cloudfront" component={CloudFrontList }/>
                <Route exact path="/logout" component={HomePage}/>
              </Switch>
            </div>
          </HashRouter>
        </div>
       
        
      </div>
    );
  }
}

export default App;