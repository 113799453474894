import React from 'react'
import './../App.css'
import { Link, HashRouter } from 'react-router-dom'
import { MDBIcon } from 'mdbreact'


export default class SideNav extends React.Component {

    render() {
        return (
            <HashRouter>
                <div class="row">
                    <div class="col-2 col-s-2 menu " style={{height:'90%'}}>
                        <ul>
                            <li style={{color:'#535454'}}><Link to='/' className="link"><MDBIcon icon="home" className="mr-2  " style={{color:'black'}} /> Dashboard</Link></li>
                            {/* <li style={{color:'#535454'}}><Link to='list' className="link"><MDBIcon icon="table" className="mr-2" style={{color:'black'}}/>List</Link></li> */}
                            <li style={{color:'#535454'}}><Link to='/chanpepass' className="link"><MDBIcon icon="unlock" className="mr-2" style={{color:'black'}} />Change Password</Link> </li>
                            <li style={{color:'#535454'}}><Link to='/s3' className="link"><MDBIcon fab icon="amazon" className="mr-2" style={{color:'black'}} />S3</Link></li>
                            <li style={{color:'#535454'}}><Link to='/cloudfront' className="link"><MDBIcon fab icon="amazon" className="mr-2"  style={{color:'black'}} />CloudFront</Link></li>
                            <li style={{color:'#535454'}}><Link to='/logout' className="link"><MDBIcon  icon="power-off" className="mr-2"  style={{color:'black'}} />LogOut</Link></li>

                        </ul>

                    </div>
                </div>
            </HashRouter>
        )
    }
}