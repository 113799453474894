import React from 'react'
import './../App.css'

export default class Footer extends React.Component {
    render() {
        return (
            <div>
                <div class="footer">
                <h6> Copyright © Ginger Webs Pvt Ltd 2014-15. All rights reserved.</h6>
               
                </div>
            </div>
        )
    }
}