import React from 'react'
import {Link, HashRouter} from 'react-router-dom'
import './../App.css'

export default class HeaderLayout extends React.Component {

    render() {
       return (
           <HashRouter>
            <div>
                <div class="header">
                    <img src={process.env.PUBLIC_URL + 'logo.png'} className='logo' />
                    {/* <button className='button'><Link to='/logout'  style={{color:'black'}}>Logout</Link></button>  */}
                </div>
                
            </div>
            </HashRouter>

        )
    }
}
