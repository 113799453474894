import React from 'react'
import './../App.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import "react-datepicker/dist/react-datepicker.css";
import SideNav from './side-nav'
import axios from 'axios';
import { AgGridReact } from 'ag-grid-react';
import DatePicker from "react-datepicker";
import { Redirect } from 'react-router-dom';
import { MDBIcon, MDBBtn } from 'mdbreact';
import { CSVLink } from "react-csv";
import { Spinner } from 'reactstrap';
import ENV from './../env.json'


var moment = require('moment-timezone');
var newStartDate;
var newEndDate;
export default class List extends React.Component {
    constructor(props) {
        super()
        this.state = {
            newStartDate: new Date(new Date(new Date().setDate(1)).setHours(0, 0, 0)),
            newEndDate: new Date(),
            columnDefs: [
                {
                    headerName: "Consumption Date", field: "consumptionDate", filter: true, resizable: true,
                    cellStyle: {
                        function(params) {
                            return params
                        }
                    }
                },
                {
                    headerName: "Resource Title", field: "resource_title", filter: true, resizable: true, cellStyle: {
                        function(params) {
                            return params
                        }
                    }
                },
                { headerName: "Customer", field: "customer", filter: true, resizable: true },
                { headerName: "Consumption", field: "consumption", filter: true, resizable: true },
                { headerName: "Consumption Unit", field: "consumption_unit", filter: true, resizable: true },
                { headerName: "Hits", field: "hits", filter: true, resizable: true }],
            rowClassRules: {
                'sick-days-warning': function (params) {
                    var numSickDays = params.data.resource_title;
                    return numSickDays === "Total";
                }
            },
            floatingFilter: true,
            rowData: [],
            filteredData: [],
            isLoading: true
        }
    }
    //Methods
    handleClick = () => {
        this.setState({ isLoading: true })
        this.getListData();
    }

    //start date end date methods
    onChangeStartDate = (date) => {
        this.setState({ newStartDate: date })
    }
    onSelectStartDate = (date) => {
        newStartDate = date
    }
    onChangeEndtDate = (date) => {
        this.setState({ newEndDate: date })
    }
    onSelectEndDate = (date) => {

        newEndDate = date
    }


    onBtForEachNodeAfterFilterAndSort = () => {

        var array = [];

        this.gridApi.forEachNodeAfterFilterAndSort(function (rowNode, index) {
            array.push(rowNode.data)
        });
        this.setState({ filteredData: array })
    };
    //ag-grid table coloring total row
    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
        this.gridApi.forEachNodeAfterFilterAndSort(function (rowNode, index) {
        });

    };
    checkDate = () => {
        let startTime = this.state.newStartDate.getTime();
        let endTime = this.state.newEndDate.getTime();
        if (startTime > endTime) {
            alert("start date should be less than end date")
            this.setState({ isLoading: false })

            return false;
        } else {

            return true;
        }
    }
    //get list data from api
    getListData = () => {
        let response = this.checkDate()
        if (response === true) {
            var initialDate = moment.tz(this.state.newStartDate, 'Asia/Kolkata').format('YYYY-MM-DD')
            var lastDate = moment.tz(this.state.newEndDate, 'Asia/Kolkata').format('YYYY-MM-DD')
            let username = sessionStorage.getItem('user')
            let rowBodys = "ParamsStart#@!dtstrt:" + initialDate + "#@!dtend:" + lastDate + "#@!user:" + username + "#@!tag:tagnm#@!Paramsend"

            let url = ENV.DASHBOARD_LIST_API;

            axios.post(
                url,
                rowBodys,
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'text/plain'
                    }
                }
            ).then(response => {
                this.setState({ isLoading: false })
                var res = response.data
                this.setState({ rowData: res });
                let name = `${username}-${initialDate}-${lastDate}-data.csv`
                this.setState({ name: name })

            });
        }
    }

    componentDidMount() {
        let user = null
        // user = localStorage.getItem("user")
        user = sessionStorage.getItem("user")
        if (user != null) {
            this.setState({ isAuthenticated: true })
        } else {
            //redirect to lopgimn
            this.setState({ isAuthenticated: false })
            return <Redirect to="/login" />
        }
        let date = new Date();
        date.setDate(1);
        date.setHours(0, 0, 0)
        newStartDate = date
        newEndDate = new Date()
        setTimeout(2000)

        this.getListData();
    }
    render() {
        const headers = [
            { label: "Consumption Date", key: "consumptionDate" },
            { label: "Resource Title", key: "resource_title" },
            { label: "Customer", key: "customer" },
            { label: "Consumption", key: "consumption" },
            { label: "Consumption Unit", key: "consumption_unit" },
            { label: "Hits", key: "hits" }

        ];
        if (this.state.isAuthenticated == false) {
            return <Redirect to="/login"></Redirect>
        }
        let loader = ""
        let table = ""
        if (this.state.isLoading === false) {
            loader = ""
            table = <div className="ag-theme-alpine" style={{ height: '700px', width: '90%', marginLeft: '2%' }}>
                <AgGridReact
                    columnDefs={this.state.columnDefs}
                    rowData={this.state.rowData}
                    pagination="true"
                    cacheBlockSize="20"
                    paginationPageSize="20"
                    rowSelection="multiple"
                    enableCellTextSelection="true"
                    onGridReady={this.onGridReady}

                    rowClassRules={this.state.rowClassRules}
                    floatingFilter={true}>
                </AgGridReact>
            </div>
        } else {
            loader = <Spinner color="black" />
            table = ""
        }

        return (
            <div >

                <div style={{ float: 'left' }}><SideNav /></div>
                <div className='row' style={{ marginLeft: '20px' }}>
                    <div className='card-style'>
                        <div className="container1" >
                            <h5><b style={{ color: 'black',marginLeft:'10px',marginTop:'10px' }}>Start Date</b></h5>

                            <DatePicker
                                className="date-style"
                                selected={this.state.newStartDate}
                                onSelect={this.onSelectStartDate}
                                onChange={this.onChangeStartDate}
                                dateFormat='yyyy-MM-dd'
                            />
                        </div>

                    </div>
                    <div className='card-style'>
                        <div className="container1" >
                            <h5><b style={{ color: 'black',marginLeft:'10px',marginTop:'10px' }}>End Date</b></h5>

                            <DatePicker
                                className="date-style"
                                selected={this.state.newEndDate}
                                onSelect={this.onSelectEndDate}
                                onChange={this.onChangeEndtDate}
                                dateFormat='yyyy-MM-dd'

                            />
                        </div>
                    </div>
                    <div className=' col-md-2' style={{ marginTop: '2%' }}>
                        <MDBBtn size="md" color="black" onClick={this.handleClick}><MDBIcon icon="search" className=' ml-2 mr-2' />search</MDBBtn>
                    </div>
                    <div className='col-md-2' style={{ marginTop: '3%' }}>
                        {loader}
                    </div>

                    <div className=' col-md-2' style={{ marginTop: '2%' }}>
                        <CSVLink data={this.state.filteredData} onClick={() => {
                            this.onBtForEachNodeAfterFilterAndSort()
                        }} headers={headers} filename={this.state.name}>
                            <MDBBtn size="md" color="black" ><MDBIcon icon="file-csv" className="mr-2  " />CSV</MDBBtn>
                        </CSVLink>
                    </div>
                    {table}
                </div>

            </div>
        )
    }
}