import React from 'react';
import './../App.css'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import Modal from 'react-modal';
import axios from 'axios';
import SideNav from './side-nav'
import { Redirect } from 'react-router-dom';
import Select from 'react-select';
import { Spinner } from 'reactstrap';
import ENV from './../env.json'

var selectedBucketName="All"

class S3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bucketname: '',
      region: null,
      modalIsOpen: false,
      isSaveBucket: false,
      isLoading: true,

      columnDefs: [
        { headerName: "Bucket Name", field: "bktName" , filter: true},
        { headerName: "Bucket Creation Date", field: "bktCreationDate" ,filter: true }],
      rowData: []
    }
  }

  //modal methods
  onModelOpen = async (e) => {
    // console.log(e)
    await this.setState({
      modalIsOpen: true
    })
    //console.log(this.state)   
  }
  //modal methhod
  onMoedalInputChang = e => {
    this.setState({ [e.target.name]: e.target.value })

  }
  onModelClick = (e) => {
    e.preventDefault();
    this.setState({ isSaveBucket: true })
    // console.log(this.state)
    let url=ENV.CREATE_BUCKET_API
    let username = sessionStorage.getItem('user')
    let rowBody = "ParamsStart#@!unm:"+ username +"#@!pwd:deep1#@!bnm:"+this.state.bucketname+"#@!Paramsend"
  
    
    axios.post(url,
      rowBody,{
        headers: {
          'Access-Control-Allow-Origin': "*",
          'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
          "Access-Control-Allow-Credentials": true,
          'Content-Type': 'text/plain'
        }

      }).then(response=>{
        // console.log("create bucket response ",response)
        this.setState({modalIsOpen:false})
        var res=response.data
        // console.log("res ",res)
        alert(res)

      })
    


  }
  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    // this.gridApi.onFilterChanged();

  };
  
  externalFilterChanged = (newValue) => {
  
    // console.log(newValue)
    if(newValue.target.value === ""){
      selectedBucketName  = "All"
    }else {
      selectedBucketName = newValue.target.value;
      
    }
    this.gridApi.onFilterChanged();
  };
  isExternalFilterPresent = () => {
    return selectedBucketName!="All";
  };
  doesExternalFilterPass = node => {

    if(selectedBucketName != 'All'){
      // console.log(node.data);
      // console.log(selectedBucketName)
      return node.data.bktName.includes(selectedBucketName);
    }else {

      return true;
    }
    

  };


  onModelIsClose = () => {
    this.setState({ modalIsOpen: false })
  }
  onBtForEachNodeAfterFilterAndSort = () => {

    var array = [];

    this.gridApi.forEachNodeAfterFilterAndSort(function (rowNode, index) {
        array.push(rowNode.data)
    });
    this.setState({ filteredData: array })
};
 

  onSearchList = async () => {
   
  }
  _onChange = (value) => {
    //console.log(value) - just to see what we recive from <Select />
    this.setState({ region: value });
  }

  componentDidMount() {
    let url = ENV.LIST_ALL_BUCKET_API
    let username = sessionStorage.getItem('user')
    // console.log(username)
    let rowBody = "ParamsStart#@!unm:" + username + "#@!pwd:deep1#@!Paramsend";
  
    axios.post(url,
      rowBody,
      {
        headers: {
          'Access-Control-Allow-Origin': "*",
          'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
          "Access-Control-Allow-Credentials": true,
          'Content-Type': 'text/plain'
        }
      }).then(response => {
        // console.log(response)
        this.setState({ isLoading: false })
        var res = response.data
        this.setState({ rowData: res })
        // console.log("res",this.state.rowData)

      })
    // this.onSearchList();

  }

  render() {
    const options = [
      { value: "us-east-2", label: 'US East (Ohio)' },
      { value: "us-east-1", label: 'US East (N. Virginia)' },
      { value: "us-west-1", label: 'US West (N. California)' },
      { value: "us-west-2", label: 'US West (Oregon)' },
      { value: "af-south-1", label: 'Africa (Cape Town) ' },
      { value: "ap-east-1", label: 'Asia Pacific(Hong Kong)' },
      { value: "ap-south-1", label: 'Asia Pacific (Mumbai) ' },
      { value: "ap-northeast-3", label: 'Asia Pacific (Osaka-Local)' },
      { value: "ap-northeast-2", label: 'Asia Pacific (Seoul)' },
      { value: "ap-southeast-1", label: 'Asia Pacific (Singapore)' },
      { value: "ap-southeast-2", label: 'Asia Pacific (Sydney)' },
      { value: "ap-northeast-1", label: 'Asia Pacific (Tokyo)' },
      { value: "ca-central-1", label: 'Canada (Central)' },
      { value: "cn-north-1", label: 'China (Beijing) ' },
      { value: "cn-northwest-1", label: 'China (Ningxia) ' },
      { value: "eu-central-1", label: 'Europe (Frankfurt)' },
      { value: "eu-west-1", label: 'Europe (Ireland)' },
      { value: "eu-west-2", label: 'Europe (London)' },
      { value: "eu-south-1", label: 'Europe (Milan)' },
      { value: "eu-west-3", label: 'Europe (Paris)' },
      { value: "eu-north-1", label: 'Europe (Stockholm)' },
      { value: "me-south-1", label: 'Middle East (Bahrain) ' },
      { value: "sa-east-1", label: 'South America (São Paulo)' }

    ];
    let loader = ""
        let table = ""
        if (this.state.isLoading === false) {
            loader = ""
            table = <div className="ag-theme-alpine" style={{ height: '600px', width: '100%', padding: ' 1rem 1rem 1rem 1rem' }}>

            <AgGridReact
              columnDefs={this.state.columnDefs}
              rowData={this.state.rowData}
              pagination="true"
              cacheBlockSize="20"
              paginationPageSize="20"
              rowSelection="multiple"
              enableCellTextSelection="true"
              floatingFilter={true} 
              isExternalFilterPresent={this.isExternalFilterPresent}
              doesExternalFilterPass={this.doesExternalFilterPass}
              onGridReady={this.onGridReady}>
            </AgGridReact>
          </div>
        } else {
            loader = <Spinner color="black" />
            table = ""
        }

    // if (this.state.isSaveBucket === true) {
    //   return <Redirect to='/list'></Redirect>
    // }
    return (
      <div >
        <div style={{ float: 'left' }}><SideNav /></div>
        <div className='row'>
          <div style={{ width: '100%', margin: '2% 2%', border: '1px grey', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' }}>

            <div>
              <Modal
                isOpen={this.state.modalIsOpen}

                style={{
                  content: {
                    top: '20%',
                    margin: '0 30%',
                    bottom: '40%',
                    textAlign: 'center',
                    width: '50%',
                    boxShadow: '-2rem 2rem 2rem rgba(0, 0, 0, 0.2)'
                  }
                }}
                ariaHideApp={false}
                contentLabel="Example Modal">
                <button className="close" onClick={this.onModelIsClose} style={{ marginRight: '5px', top: '20px' }}>
                  <span aria-hidden='true' >x</span>
                </button>
                <div style={{ fontSize: '24px', fontWeight: 'bold', padding: '1rem', backgroundColor: '#e2492f', color: 'white' }}>Add S3 Bucket</div>
                <div style={{ height: '1px', backgroundColor: '#cccccc' }}></div>
                <form>
                  <br></br>

                  <input placeholder="Bucket Name" className='form-control' type="text" name="bucketname" value={this.state.bucketname} onChange={this.onMoedalInputChang}  required />
                  <br></br>
                  {/* <MDBInput label='Region' type="text" name="region" value={this.state.region} onChange={this.onMoedalInputChang} required /> */}
                  <Select name="form-field-name" value={this.state.region} options={options} autoSize={false} onChange={this._onChange} />
                  <br></br>

                  <button style={{ backgroundColor: '#ffd143', borderRadius: '5px', border: '0', padding: '0.5rem 1rem', fontSize: '0.8rem', float: 'left', lineHeight: '1', marginLeft: '35%' }}
                    onClick={this.onModelClick}>Save</button>
                  <button style={{ backgroundColor: '#ffd143', paddingLeft: "10px", borderRadius: '5px', border: '0', padding: '0.5rem 1rem', fontSize: '0.8rem', lineHeight: '1', marginRight: '35%' }}
                    onClick={this.onModelIsClose}>Cancel</button>
                </form>
              </Modal>

            </div>
            

            <div >

              <div style={{ fontSize: '22px', fontWeight: 'bold', padding: '1rem', backgroundColor: '#e55638', color: 'white' }}> Create S3 Bucket</div>
              <div className="row" style={{margin:'10px '}}>
                <label  style={{margin:'10px', verticalAlign: 'center' }} >Search Bucket </label>
                <input  name="bucketname" onChange={this.externalFilterChanged}></input>
              
                <div style={{marginRight:'10px',marginLeft:'15px'}} >
                  <button style={{ backgroundColor: '#ffd143', borderRadius: '5px', padding: '0.5rem 1rem', fontSize: '0.8rem', float: 'left', lineHeight: '1.5', border: '0' }}
                    onClick={this.onModelOpen}>Add</button>
                </div>
               
                <div className='col-md-2' style={{ marginTop: '0.5%',marginRight:'15%' }}>
                        {loader}
                    </div>
              </div>
              {table}
            </div>
           
          </div>
        </div>
      </div>

    );
  }
}

export default S3;