import React from 'react';
import './../App.css'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import SideNav from './side-nav'
import { Redirect } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import Modal from 'react-modal';
import Select from 'react-select';
import ENV from './../env.json'

class CloudFrontList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSaveDist: false,
            isLoading: true,
            modalIsOpen: false,
            distributionName:'',
            region:'',

            columnDefs: [
                { headerName: "Delivery Method", field: "deliveryMethod" },
                { headerName: "Domain Name", field: "domainName" },
                { headerName: "Comment", field: "comment" },
                { headerName: "Origin", field: "origin" },
                { headerName: "Price Class", field: "priceClass" },
                { headerName: "C Name", field: "cname" },
                { headerName: "Status", field: "status" },
                { headerName: "State", field: "state" },
                { headerName: "Id", field: "id" },],

            rowData: []
        }
    }
    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();

    };

    onSearchList = async () => {
        let url = ENV.CLODFRONT_LIST_API
        let username = sessionStorage.getItem('user')
        let rowBody = "ParamsStart#@!unm:" + username + "#@!pwd:deep1#@!Paramsend";

        await axios.post(url,
            rowBody,
            {
                headers: {
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
                    "Access-Control-Allow-Credentials": true,
                    'Content-Type': 'text/plain'
                }
            }).then(response => {
                console.log(response)
                this.setState({ isLoading: false })
                var res = response.data
                this.setState({ rowData: res })

            })
    }
    //modal methods
  onModelOpen = async (e) => {
    // console.log(e)
    await this.setState({
      modalIsOpen: true
    })
    //console.log(this.state)   
  }
  //modal methhod
  onMoedalInputChang = e => {
    this.setState({ [e.target.name]: e.target.value })

  }
  onModelIsClose = () => {
    this.setState({ modalIsOpen: false })
  }
  onModelClick = (e) => {

    e.preventDefault();
    this.setState({ isSaveDist: true })
    console.log(this.state)
    let url=ENV.CLOUDFRONT_CREATE_DIST_API
    let username = sessionStorage.getItem('user')
    let rowBody = "ParamsStart#@!unm:"+ username +"#@!pwd:deep1#@!bnm:"+this.state.distributionName+"#@!Paramsend"
  
    
    axios.post(url,
      rowBody,{
        headers: {
          'Access-Control-Allow-Origin': "*",
          'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
          "Access-Control-Allow-Credentials": true,
          'Content-Type': 'text/plain'
        }

      }).then(response=>{
          console.log("in cloudfront")
        console.log("create distribution response ",response)
        this.setState({modalIsOpen:false})
        var res=response.data
        console.log("res ",res)
        console.table(this.state)
        // alert(res.data)
     

      })

  }
  _onChange = (value) => {
    //console.log(value) - just to see what we recive from <Select />
    this.setState({ region: value });
  }

    componentDidMount() {
        this.onSearchList();

    }

    render() {
        const options = [
            { value: "us-east-2", label: 'US East (Ohio)' },
            { value: "us-east-1", label: 'US East (N. Virginia)' },
            { value: "us-west-1", label: 'US West (N. California)' },
            { value: "us-west-2", label: 'US West (Oregon)' },
            { value: "af-south-1", label: 'Africa (Cape Town) ' },
            { value: "ap-east-1", label: 'Asia Pacific(Hong Kong)' },
            { value: "ap-south-1", label: 'Asia Pacific (Mumbai) ' },
            { value: "ap-northeast-3", label: 'Asia Pacific (Osaka-Local)' },
            { value: "ap-northeast-2", label: 'Asia Pacific (Seoul)' },
            { value: "ap-southeast-1", label: 'Asia Pacific (Singapore)' },
            { value: "ap-southeast-2", label: 'Asia Pacific (Sydney)' },
            { value: "ap-northeast-1", label: 'Asia Pacific (Tokyo)' },
            { value: "ca-central-1", label: 'Canada (Central)' },
            { value: "cn-north-1", label: 'China (Beijing) ' },
            { value: "cn-northwest-1", label: 'China (Ningxia) ' },
            { value: "eu-central-1", label: 'Europe (Frankfurt)' },
            { value: "eu-west-1", label: 'Europe (Ireland)' },
            { value: "eu-west-2", label: 'Europe (London)' },
            { value: "eu-south-1", label: 'Europe (Milan)' },
            { value: "eu-west-3", label: 'Europe (Paris)' },
            { value: "eu-north-1", label: 'Europe (Stockholm)' },
            { value: "me-south-1", label: 'Middle East (Bahrain) ' },
            { value: "sa-east-1", label: 'South America (São Paulo)' }
      
          ];
        let loader = ""
        let table = ""
        if (this.state.isLoading === false) {
            loader = ""
            table = <div className="ag-theme-alpine" style={{ height: '600px', width: '100%', padding: ' 1rem 1rem 1rem 1rem' }}>

                <AgGridReact
                    columnDefs={this.state.columnDefs}
                    rowData={this.state.rowData}
                    pagination="true"
                    cacheBlockSize="20"
                    paginationPageSize="20"
                    rowSelection="multiple"
                    enableCellTextSelection="true"
                    floatingFilter={true}
                    onGridReady={this.onGridReady}>
                </AgGridReact>
            </div>
        } else {
            loader = <Spinner color="black" />
            table = ""
        }
       


        // if (this.state.isSaveBucket === true) {
        //     return <Redirect to='/list'></Redirect>
        // }
        return (

            <div >

                <div style={{ float: 'left' }}><SideNav /></div>
                <div>
                    <Modal
                        isOpen={this.state.modalIsOpen}

                        style={{
                            content: {
                                top: '20%',
                                margin: '0 30%',
                                bottom: '40%',
                                textAlign: 'center',
                                width: '50%',
                                boxShadow: '-2rem 2rem 2rem rgba(0, 0, 0, 0.2)'
                            }
                        }}
                        ariaHideApp={false}
                        contentLabel="Example Modal">
                        <button className="close" onClick={this.onModelIsClose} style={{ marginRight: '5px', top: '20px' }}>
                            <span aria-hidden='true' >x</span>
                        </button>
                        <div style={{ fontSize: '24px', fontWeight: 'bold', padding: '1rem', backgroundColor: '#e2492f', color: 'white' }}>Create Distribution</div>
                        <div style={{ height: '1px', backgroundColor: '#cccccc' }}></div>
                        <form>
                            <br></br>

                            <input placeholder="Distribution name" className='form-control' type="text" name="distributionName" value={this.state.distributionName} onChange={this.onMoedalInputChang} required />
                            <br></br>
                            {/* <MDBInput label='Region' type="text" name="region" value={this.state.region} onChange={this.onMoedalInputChang} required /> */}
                            <Select name="form-field-name" value={this.state.region} options={options} autoSize={false} onChange={this._onChange} />
                            <br></br>

                            <button style={{ backgroundColor: '#ffd143', borderRadius: '5px', border: '0', padding: '0.5rem 1rem', fontSize: '0.8rem', float: 'left', lineHeight: '1', marginLeft: '35%' }}
                                onClick={this.onModelClick}>Save</button>
                            <button style={{ backgroundColor: '#ffd143', paddingLeft: "10px", borderRadius: '5px', border: '0', padding: '0.5rem 1rem', fontSize: '0.8rem', lineHeight: '1', marginRight: '35%' }}
                                onClick={this.onModelIsClose}>Cancel</button>
                        </form>
                    </Modal>

                </div>
                <div className='row'>
                    <div style={{ width: '100%', margin: '2% 2%', border: '1px grey', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' }}>

                        <div style={{ fontSize: '22px', fontWeight: 'bold', padding: '1rem', backgroundColor: '#e55638', color: 'white' }}> CloudFront Distributions</div>
                        <div>
                            <button style={{ backgroundColor: '#ffd143', borderRadius: '5px', border: '0', padding: '0.5rem 1rem', fontSize: '0.8rem', lineHeight: '1.5', marginLeft: ' 1rem ', marginTop: '10px' }}
                                onClick={this.onModelOpen}>Create Distribution</button>

                        </div>

                        <div className='col-md-2' style={{ marginTop: '1%', marginRight: '15%' }}>
                            {loader}
                        </div>

                        {table}

                    </div>

                </div>
            </div>

        );
    }
}

export default CloudFrontList;