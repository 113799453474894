import React from 'react'
import axios from 'axios';
import { Redirect } from 'react-router-dom'
import { Card } from 'reactstrap'
import { MDBIcon, MDBCardHeader } from 'mdbreact'
import { Link } from 'react-router-dom'
import ENV from './../env.json'
// import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
// import Amplify, { Auth } from 'aws-amplify';
// import awsconfig from './../aws-exports';

// Amplify.configure(awsconfig);


class HomePage extends React.Component {
  documentData;
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.state = {
      username: '',
      password: '',
      isClick: false,

    }
  }


  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }
  // on form submit...
  handleFormSubmit = async (e) => {
    e.preventDefault()

    // var username= "rubykumari"
    // var password = "Kk123456!"
    // try {
    //   const user = await Auth.signIn(username,password);
    //  console.log({ user });
    //   // this.props.onStateChange("signedIn", {});
    // } catch (err) {
    //   console.log('error signing up:', err);
    // }

    let url = ENV.LOGIN_VERIFICATION_API
    let dynamicBody = "ParamsStart#@!unm:" + this.state.username + "#@!pwd:" + this.state.password + "#@!Paramsend"

    axios.post(
      url,
      dynamicBody,
      {
        headers: {
          'Content-Type': 'text/plain'
        }
      }
    ).then(response => {

      console.log(response.data)
      if (response.data === true) {
        let user = this.state.username;
        sessionStorage.setItem('user', user)
        this.setState({ isClick: true });
      } else {
        // localStorage.clear();
        this.setState({ isClick: false });
        alert("invalid username and password!")
      }
      console.log(this.state.data);
    });
  }
  // React Life Cycle
  componentDidMount() {
    this.documentData = JSON.parse(sessionStorage.getItem('document'));
    if (sessionStorage.getItem('document')) {
      this.setState({
        username: this.documentData.username,
        password: this.documentData.password
      })
    } else {
      this.setState({
        username: '',
        password: ''
      })
    }
    //this.handleFormSubmit() ;
  }
  render() {
    const isClick = this.state.isClick
    let loader = ''
    if (isClick === true) {
      console.log("in redirect")
      return <Redirect to="/list" />
    } else {


    }
    if (this.props.ishidden === true) {
      loader = ''
    } else {
      loader = <li className='list'>
        <div style={{ color: '#495057 ' }} ><MDBIcon icon="lock" className='mr-2' /></div>
        <Link style={{ color: '#495057' }} to="/logout"> Change Password</Link>
      </li>
    }
    return (
      <div className="container" >
        {/* <AmplifySignOut/> */}

        <Card style={{ width: '560px', height: '400px', margin: '8% 25%', backgroundColor: 'white' }}>
          <MDBCardHeader style={{ background: "linear-gradient(40deg,#ffd86f,#fc6262)" }}>
            <h3 className="my-3">
              <MDBIcon icon="lock" /> Login
            </h3>
          </MDBCardHeader>
          <form onSubmit={this.handleFormSubmit}>
            <div className="form-group" style={{ margin: '20px', padding: '10px' }}>
              <div className='row' style={{ margin: '30px 0px' }}>
                <div className='col-md-1'>
                  <i className="fa fa-user-alt fa-2x "></i>
                </div>
                <div className='col-md-10'>
                  <input type="text" name="username" style={{ margin: '0px 15px ', width: '90%', padding: '10px' }} placeholder='Enter your username' value={this.state.username} onChange={this.handleChange} />
                </div>
              </div>
              <div className='row' style={{ margin: '30px 0px' }}>
                <div className='col-md-1'>
                  <i className="fa fa-key fa-2x"></i>
                </div>
                <div className='col-md-10' >
                  <input type="password" name="password" placeholder='Enter your password' value={this.state.password} onChange={this.handleChange} style={{ margin: '0px 15px ', width: '90%', padding: '10px' }} />
                </div>
              </div>
            </div>

            <button type="submit" style={{ marginLeft: '180px', border: '1px solid black', width: '200px', background: "linear-gradient(40deg,#ffd86f,#fc6262)", height: '40px' }}
              onClick={this.handleFormSubmit}>Submit
            </button>
          </form>
        </Card>

      </div>

    )
  }

}

// export default withAuthenticator(HomePage);
export default HomePage;
